<template>
  <div>
    <b-list-group flush>
      <b-list-group-item
        class="mb-3 px-md-0"
        v-for="(system, index) in systems"
        :key="index"
      >
        <div>
          <span class="d-block">
            {{ system.name }}
          </span>
          <small
            class="text-info mb-3 mt-2 d-block"
            v-if="
              system.system_required && system.management_systems.length === 0
            "
          >
            Se for próprio ou terceirizado é necessário indicar o(s) sistema(s)
          </small>
          <div class="mt-2">
            <b-button
              size="sm"
              class="mr-2"
              v-for="(option, i) in getOptions(system)"
              :key="i"
              @click="handleChangeSystemType(option.system_type, system, index)"
              :variant="
                system.system_type === option.system_type
                  ? 'secondary'
                  : 'light'
              "
            >
              {{ option.name }}
            </b-button>
          </div>
        </div>
        <div class="mt-2" v-if="system.system_type !== 3">
          <div
            v-for="(managementSystem, i) in system.management_systems"
            :key="i"
          >
            <div class="pl-0 d-md-flex align-systems-end mb-3">
              <b-form-group label="Nome do sistema" class="mr-3 mb-0 w-100">
                <b-input v-model="managementSystem.name" />
              </b-form-group>
              <b-form-group
                label="CNPJ da empresa"
                class="mr-3 mb-0 w-100"
                v-mask="'##.###.###/####-##'"
                return-masked-value
                v-if="system.system_type === 2"
              >
                <b-input
                  v-model="managementSystem.cnpj"
                  @change="handleChangesystemManagement(index)"
                />
              </b-form-group>

              <b-form-group label="E-mail do representante" class="mb-0 w-100">
                <b-input
                  v-model="managementSystem.contact"
                  @change="handleChangesystemManagement(index)"
                />
              </b-form-group>
              <b-button
                variant="light"
                size="sm"
                pill
                class="ml-2"
                @click="handleRemoveSystem(system, i)"
              >
                <b-icon icon="trash" />
              </b-button>
            </div>
          </div>
          <b-card v-if="system.inAdd">
            <div class="pl-0 d-md-flex align-systems-end mb-3">
              <b-form-group label="Nome do sistema" class="mr-2 mb-0 w-100">
                <b-input autofocus v-model="newSystemName" />
              </b-form-group>
              <b-form-group
                label="CNPJ da empresa"
                class="mr-3 mb-0 w-100"
                v-mask="'##.###.###/####-##'"
                return-masked-value
                v-if="system.system_type === 2"
              >
                <b-input v-model="newSystemEnterprise" />
              </b-form-group>
              <b-form-group label="E-mail do representante" class="mb-0 w-100">
                <b-input
                  :class="invalidEmail && 'is-invalid'"
                  v-model="newSystemContact"
                />
                <b-form-invalid-feedback v-if="invalidEmail">
                  {{ $t("auth.type-valid-email") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex align-systems-center justify-content-end">
              <b-button variant="link" size="sm" @click="system.inAdd = false">
                Cancelar
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="ml-2"
                @click="handleAddNewSystem(system, index)"
              >
                Salvar
              </b-button>
            </div>
          </b-card>
          <b-button
            variant="primary"
            size="sm"
            pill
            v-if="!systems.some(({ inAdd }) => inAdd)"
            @click="handleOpenAddSystem(system, index)"
          >
            <b-icon icon="plus" />
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { isValidEmail } from "@/utils/validations/email";

export default {
  data() {
    return {
      newSystemName: "",
      newSystemContact: "",
      newSystemEnterprise: "",
      invalidEmail: false,
      menus: [],
      systems: [],
      options: [
        { name: "Próprio", system_type: 1 },
        { name: "Terceirizado", system_type: 2 },
        { name: "Não possui", system_type: 3 },
      ],
      options2: [
        { name: "Sim", system_type: 1 },
        { name: "Não", system_type: 3 },
      ],
      params: {},
      selectedsystem: {},
    };
  },
  beforeMount() {
    this.action_name = "system";
  },
  methods: {
    ...mapActions(["edit_system_management", "get_all_system_management"]),
    getOptions(system){
      let options
      if (system.has_outsourced){
        options = this.options
      } else {
        options = this.options2
      }
      return options
    },
    handleOpenAddSystem(system, index) {
      this.systems[index].inAdd = true;
    },
    handleChangeSystemType(system_type, system, index) {
      this.systems[index].system_type = system_type;
      if (this.systems[index].system_type === 3) {
        this.systems[index].management_systems = [];
      }
      this.$forceUpdate();
      this.handleSaveChanges(index);
    },
    closeAddAndResetInputs(index) {
      this.systems[index].inAdd = false;
      this.newSystemName = "";
      this.newSystemContact = "";
      this.newSystemEnterprise = "";
    },
    handleRemoveSystem(system, index) {
      system.management_systems.splice(index, 1);
      this.handleSaveChanges(this.systems.indexOf(system));
      this.closeAddAndResetInputs(index);
    },
    handleAddNewSystem(system, index) {
      if (!(this.newSystemName && this.newSystemContact)) {
        return;
      }
      if (!isValidEmail(this.newSystemContact)) {
        this.invalidEmail = true;
        return;
      }
      this.invalidEmail = false;
      this.systems[index].management_systems.push({
        name: this.newSystemName,
        contact: this.newSystemContact,
        cnpj: this.newSystemEnterprise,
      });

      this.handleSaveChanges(index);
      this.closeAddAndResetInputs(index);
    },
    handleChangesystemManagement(index) {
      this.handleSaveChanges(index);
    },
    handleSaveChanges(index) {
      delete this.systems[index].inAdd;

      const payload = {
        ...this.systems[index],
        management_systems: this.systems[index].management_systems.map(
          (system) => {
            return {
              ...system,
              cnpj: system.cnpj?.replace(/[^\d]/g, ""),
            };
          }
        ),
      };

      this.edit_system_management(payload).then(() => {
        this.getAllSystems();
      });
    },
    addPremisesInputs(systems) {
      if (systems) {
        return (this.systems = systems.map((item) => ({
          ...item,
          inAdd: false,
        })));
      }
      this.systems = this.systems.map((item) => ({
        ...item,
        inAdd: false,
      }));
    },
    getAllSystems() {
      this.get_all_system_management().then((response) => {
        this.systems = response.results;
        this.addPremisesInputs();
      });
    },
  },
  created() {
    this.params.profile_id = 4;
  },
  mounted() {
    this.getAllSystems();
  },
  watch: {
    systems: {
      handler(newitems, oldItems) {
        const srtNewitems = JSON.stringify(newitems);
        const srtOldItems = JSON.stringify(oldItems);
        if (srtNewitems !== srtOldItems) {
          this.addPremisesInputs(newitems);
        }
      },
      deep: true,
    },
  },
};
</script>
